<template>
  <div class="contentArea  w-full" style="color: #6A7E91;">
    <!-- <div></div> -->
    <div class="bg-white p-6 flex flex-col" >

      <!-- header -->
      <div class="grow-0 flex flex-col gap-4 lg:flex-row mb-5 lg:justify-between lg:items-start">
        <div class="lg:w-5/12 flex flex-col flex-shrink gap-4">
          <div class="flex items-center gap-3">
            <span class="font-semibold whitespace-no-wrap">Campaign name:</span>
            <input class="text-input" type="text" v-model="campaign.campaign_name">
            <img @click="updateCampaignName" class="pointer w-10 h-10" src="@/assets/images/elements/check.svg" alt="">
          </div>
          <div class="flex items-center gap-3">
            <span class="font-semibold whitespace-no-wrap">Auto message keyword:</span>
            <input type="text" class="text-input" v-model="campaign.line_keyword">
            <img @click="updateCampaignKeyword" class="pointer w-10 h-10" src="@/assets/images/elements/check.svg" alt="">
          </div>
          <div class="flex w-full gap-3">
            <div class="flex w-full items-center gap-3">
              <span class="font-semibold whitespace-no-wrap">Start date:</span>
              <DatePicker @dayclick="updateCampaignDate()" class="w-full" mode="date" v-model="campaign.start_date"
                :masks="masks">
                <template v-slot="{ inputValue, inputEvents }">
                  <input class="text-input" :value="inputValue" v-on="inputEvents" placeholder="Start date" type="text"
                    required>
                </template>
              </DatePicker>
            </div>
            <div class="flex w-full items-center gap-3">
              <span class="font-semibold whitespace-no-wrap">End date:</span>
              <DatePicker @dayclick="updateCampaignDate()" class="w-full" mode="date" v-model="campaign.end_date"
                :masks="masks">
                <template v-slot="{ inputValue, inputEvents }">
                  <input class="text-input" :value="inputValue" v-on="inputEvents" placeholder="Start date" type="text"
                    required>
                </template>
              </DatePicker>
            </div>
          </div>
        </div>
        <div class="lg:w-5/12 flex flex-col items-end gap-4">
          <div class="flex flex-col w-full md:8/12 lg:w-7/12 xl:6/12 gap-3 justify-end justify-items-end cus-select ">
            <b-form-select  class="w-full" v-model="campaign.is_publish" @change="updateCampaignIsPublish" :options="[{text:'Publish' ,value:true} , {text:'Unpublish' ,value:false}]"></b-form-select>
            <div class="flex gap-3 w-full">
              <button @click="previewLandingPage" class="border-button border-button-pri w-full">Preview</button>
              <button @click="updateCampaignHtml" class="border-button border-button-pri w-full">Update</button>
            </div>
          </div>
          
          <div class="flex w-full gap-4">
            <input placeholder="url" ref="campaign_url" v-model="campaign.web_url" readonly class="text-input"
              type="text">
            <button @click="copy" class="border-button border-button-pri">copy</button>
          </div>
          <p v-if="campaign.lastest_update" class="text-right">Last updated : {{ formatDate }} </p>
        </div>
      </div>

      
      <!-- upload -->

      <span class="font-semibold grow-0">Upload images</span>
      <div class="my-8 grow-0 flex flex-col gap-4 items-center sm:flex-row sm:justify-around">

        <!-- header -->
        <div >
          <div class="img-box">
            <img v-if="campaign.header_img" @click="OpenImageModal(addTimeStamp(campaign.header_img))" class="object-contain img-element pointer" :src="addTimeStamp(campaign.header_img)"
              alt="">
            <div class="flex items-center justify-center h-full" v-else>
              <img @click="$refs.uploadHeaderImg.click()" src="@/assets/images/elements/plus.svg" alt="">
            </div>
            <input style="display: none" ref="uploadHeaderImg" accept="image/png, image/gif, image/jpeg"
              @change="onFileHeaderSelected($event)" type="file">
          </div>
          <div class="flex justify-center mt-3 gap-3">
            Header
            <img @click="$refs.uploadHeaderImg.click()" class="pointer" src="@/assets/images/elements/pensil.svg" alt="">
            <img @click="deleteHeaderImg()" class="pointer" src="@/assets/images/icon/del.svg" alt="">
          </div>

        </div>

        <!-- footer -->
        <div>
          <div class="img-box">
            <img v-if="campaign.footer_img" @click="OpenImageModal(addTimeStamp(campaign.footer_img))"  class="object-contain img-element pointer" :src="addTimeStamp(campaign.footer_img)"
              alt="">
            <div class="flex items-center justify-center h-full" v-else>
              <img @click="$refs.uploadFooterImg.click()" src="@/assets/images/elements/plus.svg" alt="">
            </div>
            <input style="display: none" ref="uploadFooterImg" accept="image/png, image/gif, image/jpeg"
              @change="onFileFooterSelected($event)" type="file">
          </div>
          <div class="flex justify-center mt-3 gap-3">
            Footer
            <img @click="$refs.uploadFooterImg.click()" class="pointer" src="@/assets/images/elements/pensil.svg" alt="">
            <img @click="deleteFooterImg()" class="pointer" src="@/assets/images/icon/del.svg" alt="">
          </div>

        </div>


      </div>


      <!-- brand -->
      <span class="font-semibold grow-0">Brands</span>
      <div class="flex flex-col gap-4 lg:flex-row justify-between grow-0 my-5">
        <div class="grid grid-cols-3 sm:flex gap-5">
          <button class="fill-button fill-button-pri" @click="openCreateBrandModal">Create New Brand</button>
          <button class="border-button border-button-pri" :disabled="!allowClick" :class="{'border-button-disable':!allowClick}" @click="publishCampaign">Publish</button>
          <button class="border-button border-button-pri" :disabled="!allowClick" :class="{'border-button-disable':!allowClick}" @click="unPublishCampaign">Unpublish</button>
          <button class="border-button border-button-red" :disabled="!allowClick" :class="{'border-button-disable':!allowClick}" @click="openDeleteBrandModal">Delete</button>
        </div>
        <div>
          <input class="text-input" v-model="searchText" placeholder="search" />
        </div>

      </div>
      <!-- <BrandTable /> -->
        <BrandTable ref="BrandTable" @AllowClick="isAllowClick" :rowData="rowData" :searchText="searchText" @brand-up="BrandUp"
          @brand-down="BrandDown" class="grow" />
        <!-- <BrandTable ref="BrandTable" :rowData="rowData" :searchText="searchText" @brand-up="BrandUp"
          @brand-down="BrandDown" /> -->

    </div>

    <!-- Create Brand Modal -->
    <BlankModal v-if="showCreateBrandModal" @close="closeCreateBrandModal">
      <template v-slot:body>
        <div class="p-4 container">
          <form @submit.prevent="createBrand">
            <p class="font-semibold text-center">Create Brand</p>
            <div class="flex flex-col gap-2 sm:flex-row items-center justify-around">
              <!-- Thumbnail -->
              <div>
                <div class="img-box ">
                  <img v-if="brand_thumbnail_img_pre" class="object-contain img-element" :src="brand_thumbnail_img_pre"
                    alt="">
                  <div class="flex items-center justify-center h-full" v-else>
                    <img @click="$refs.uploadBrandThumbnailImg.click()" src="@/assets/images/elements/plus.svg" alt="">
                  </div>
                  <input style="display: none" ref="uploadBrandThumbnailImg" accept="image/png, image/gif, image/jpeg"
                    @change="onFileBrandThumbnailSelected($event)" type="file">
                </div>
                <div class="flex justify-center gap-3 font-semibold my-2">
                  Thumbnail
                  <img @click="$refs.uploadBrandThumbnailImg.click()" class="pointer" src="@/assets/images/elements/pensil.svg" alt="">
                  <img @click="deleteBrandThumbnailImg" class="pointer" src="@/assets/images/icon/del.svg" alt="">
                </div>
              </div>

              <!-- header -->
              <div>
                <div class="img-box ">
                  <img v-if="brand_header_img_pre" class="object-contain img-element" :src="brand_header_img_pre"
                    alt="">
                  <div class="flex items-center justify-center h-full pointer"
                    @click="$refs.uploadBrandHeaderImg.click()" v-else>
                    <img src="@/assets/images/elements/plus.svg" alt="">
                  </div>
                  <input style="display: none" ref="uploadBrandHeaderImg" accept="image/png, image/gif, image/jpeg"
                    @change="onFileBrandHeaderSelected($event)" type="file">
                </div>
                <div class="flex justify-center gap-3 font-semibold my-2">
                  Header
                  <img @click="$refs.uploadBrandHeaderImg.click()" class="pointer" src="@/assets/images/elements/pensil.svg" alt="">
                  <img @click="deleteBrandHeaderImg()" class="pointer" src="@/assets/images/icon/del.svg" alt="">
                </div>
              </div>

              <!-- footer -->
              <div>
                <div class="img-box ">
                  <img v-if="brand_footer_img_pre" class="object-contain img-element" :src="brand_footer_img_pre"
                    alt="">
                  <div class="flex items-center justify-center h-full pointer"
                    @click="$refs.uploadBrandFooterImg.click()" v-else>
                    <img src="@/assets/images/elements/plus.svg" alt="">
                  </div>
                  <input style="display: none" ref="uploadBrandFooterImg" accept="image/png, image/gif, image/jpeg"
                    @change="onFileBrandFooterSelected($event)" type="file">
                </div>
                <div class="flex justify-center gap-3 font-semibold my-2">
                  Footer
                  <img @click="$refs.uploadBrandFooterImg.click()" class="pointer" src="@/assets/images/elements/pensil.svg" alt="">
                  <img @click="deleteBrandFooterImg()" class="pointer" src="@/assets/images/icon/del.svg" alt="">
                </div>

              </div>

            </div>
            <div class="mb-5 text-center">Maximum image size: 10 MB</div>


            <div class="flex flex-col gap-4 mb-4">
              <div>
                <label for="brand-input">Brand name</label>
                <input class="text-input" id="brand-input" v-model="new_brand" placeholder="Brand name" type="text" required>
              </div>
             
            </div>

            <div class="flex justify-center gap-4">
              <button type="button" @click="closeCreateBrandModal"
                class="border-button border-button-red">Cancel</button>
              <button type="submit" class="border-button border-button-pri">Create</button>
            </div>
          </form>
        </div>

      </template>

    </BlankModal>

    <!-- delete brand modal -->
    <MiniBlankModal v-if="showDeleteBrandModal" @close="closeDeleteBrandModal">

      <template v-slot:body>
        <div class="flex flex-col gap-4 p-4">
          <p class="font-semibold text-center">Are you sure that you want to delete this product?</p>

          <div class="flex justify-around">
            <button @click="closeDeleteBrandModal" style="background-color: rgb(235, 235, 235);"
              class="border-button border-button-pri">Cancel</button>
            <button @click="deleteBrand" style="background-color: red; color:#FFFFFF;"
              class="border-button border-button-pri">Delete</button>
          </div>
        </div>

      </template>

    </MiniBlankModal>

    <!-- preview modal -->
    <BlankModal v-if="showPreviewLanding" @close="closePreviewLanding">
      <template v-slot:body>
        <div v-html="previewLanding"></div>
      </template>
    </BlankModal>

     <!-- Preview Image -->
     <BlankModal v-if="showPreviewImage" @close="closePreviewImage">
        <template v-slot:body>
          <div class="w-full flex justify-center img-modal">
            <img class="object-contain img-modal" :src="modal_img_url" alt="">
          </div>
        </template>
      </BlankModal>

  </div>


</template>

<script>
import AgGrid from "../elements/ag-grid-table/AgGridTable.vue";
// import CampaignTable from "../elements/ag-grid-table/CampaignTable.vue"
import BrandTable from "../elements/ag-grid-table/BrandTable.vue";
import BlankModal from "../../components/blankModal.vue";
import MiniBlankModal from "../../components/miniblankModal.vue"
import axios from "../../axios";
import { baseurl } from "../../util/baseUrl";
import { DatePicker } from "v-calendar"
import { async } from "q";
import { throws } from "assert";
import moment from "moment";
import Compressor from "compressorjs";



export default {
  async created() {
  },
  data() {
    return {
      searchText: null,
      rowData: null,
      

      campaign: null,

      footer_img: null,
      footer_img_pre: null,
      foorer_img_url: null,
      header_img: null,
      header_img_pre: null,
      header_img_url: null,

      new_brand: null,
      brand_footer_img: null,
      brand_footer_img_pre: null,
      brand_foorer_img_url: null,
      brand_header_img: null,
      brand_header_img_pre: null,
      brand_header_img_url: null,
      brand_thumbnail_img: null,
      brand_thumbnail_img_pre: null,
      brand_thumbnail_img_url: null,

      showCreateBrandModal: false,
      showDeleteBrandModal: false,

      previewLanding: null,
      showPreviewLanding: false,

      showPreviewImage: false,
      modal_img_url: null,

      masks: {
        input: 'DD/MM/YYYY'
      },

      allowClick: false
    }
  },
  async beforeMount() {
    await this.getAllBrand()
    await this.$store.dispatch("general/push_activePage",{
        name: this.campaign.campaign_name
         })
  },

  computed: {
    formatDate() {
      return moment(this.campaign.lastest_update).format("DD/MM/YY HH:MM")
    }
  },

  methods: {
    copy() {
      let url = this.$refs.campaign_url
      url.innerHTML = window.location.href;
      console.log(url.innerHTML)
      url.select();
      document.execCommand('copy');
    },
    openCreateBrandModal() {
      this.showCreateBrandModal = true
    },
    openDeleteBrandModal() {
      this.showDeleteBrandModal = true
    },

    closeDeleteBrandModal() {
      this.showDeleteBrandModal = false
    },

    closeCreateBrandModal() {
      this.brand_footer_img = null
      this.brand_footer_img_pre = null
      this.brand_foorer_img_url = null
      this.brand_header_img = null
      this.brand_header_img_pre = null
      this.brand_header_img_url = null
      this.brand_thumbnail_img = null
      this.brand_thumbnail_img_pre = null
      this.brand_thumbnail_img_url = null

      this.new_brand = null
      this.showCreateBrandModal = false
    },

    async createBrand() {
      try {
        this.$vs.loading({color: "#DDDDDD"});

        
        let brand_thumbnail_img_url = this.brand_thumbnail_img? await uploadFileBrand(this.brand_thumbnail_img, this.new_brand, this.campaign.campaign_name, "thumbnail"):null
        let brand_header_img_url = this.brand_header_img?  await uploadFileBrand(this.brand_header_img, this.new_brand, this.campaign.campaign_name, "header"):null
        let brand_footer_img_url = this.brand_footer_img? await uploadFileBrand(this.brand_footer_img, this.new_brand, this.campaign.campaign_name, "footer"):null

        await axios.post(`${baseurl()}/api/admin/createBrand`, {
          campaign_id: this.campaign.campaign_id,
          brand_name: this.new_brand,
          thumbnail_img: brand_thumbnail_img_url,
          header_img: brand_header_img_url,
          footer_img: brand_footer_img_url,
          brand_url: "/"
        })
        await this.getAllBrand()
        this.showCreateBrandModal = false
        this.new_brand = null
        this.brand_footer_img = null
        this.brand_footer_img_pre = null
        this.brand_foorer_img_url = null
        this.brand_header_img = null
        this.brand_header_img_pre = null
        this.brand_header_img_url = null
        this.brand_thumbnail_img = null
        this.brand_thumbnail_img_pre = null
        this.brand_thumbnail_img_url = null
        this.$vs.loading.close();
      } catch (error) {
        console.log(error);
        if(error.response.data.message){
          alert(error.response.data.message)
        }else{
          alert(error)
        }
        this.$vs.loading.close();
      }

    },
    async deleteBrand() {
      let rows = await this.$refs.BrandTable.getSelectedRowData()
      // console.log("rows:", rows);
      let brand_arr = rows.map((each) => { return each.brand_detail.brand_id })
      await axios
        .delete(`${baseurl()}/api/admin/deleteBrandMultiple`, {
          data: {
            brand_id: brand_arr
          }

        })
        .then(async (res) => {
          // console.log(res);
          this.showDeleteBrandModal = false
          await this.getAllBrand()
        })
        .catch((err) => {
          console.log(err);
          this.showDeleteBrandModal = false
        })
    },

    async getAllBrand() {
      try {
        let res = await axios.get(`${baseurl()}/api/admin/getCampaignDetailed/${this.$route.params.campaign_id}`)
        // console.log(res);
        this.campaign = res.data.campaign
        // console.log("campaign:", this.campaign);
        this.footer_img_url = this.campaign.footer_img
        this.header_img_url = this.campaign.header_img

        // console.log(this.campaign);
        this.rowData = []
        // console.log(this.campaign.brands);
        await this.campaign.brands.map((each, index) => {
          // console.log("brand_name", each.brand_name);
          this.rowData.push({
            no: index + 1,
            up: { campaign_id: this.campaign.campaign_id, brand_id: each.brand_id },
            down: { campaign_id: this.campaign.campaign_id, brand_id: each.brand_id },
            brand_name: each.brand_name,
            is_publish: {
              brand_id: each.brand_id,
              is_publish: each.is_publish
            },
            brand_detail: {
              brand_id: each.brand_id
            }
          })
        })

      } catch (error) {
        if(error.response.data){
          alert(error.response.data.message)
          if(error.response.data.message === "Not found"){
            this.$router.push({name:"campaign"})
          }
        }else{
          alert(error)
        }
     
      }
    },


    async onFileHeaderSelected(e) {
      const target = e.target
      // console.log(target.files.length);
      const file = (target.files)[0]

      console.log('file:', file.type.match('image.*'))
      const FileSize = file.size

      console.log('size:', FileSize)
      if (!file.type.match('image.*')) {
        alert('Unsuccessful Upload. Please make sure to upload a image file.')
        return
      }
      if (FileSize > 10000000) {
        alert(
          'Unsuccessful Upload. Please make sure to upload file size less than 10 MB.'
        )
        return
      }

      if (file.type.match('image.*')) {
        console.log(file);
        this.header_img = file;
        this.header_img_pre = await getBase64(file)
        let header_img_url = await uploadFileCampaign("header", this.campaign.campaign_name, this.header_img)
        await axios.put(`${baseurl()}/api/admin/updateCampaign`, {
          campaign_id: this.campaign.campaign_id,
          header_img: header_img_url
        })
        await this.getAllBrand()
      }
      e.target.value = null
    },

    async onFileFooterSelected(e) {
      const target = e.target
      console.log(target.files.length);
      const file = (target.files)[0]

      console.log('file:', file.type.match('image.*'))
      const FileSize = file.size

      console.log('size:', FileSize)
      if (!file.type.match('image.*')) {
        alert('Unsuccessful Upload. Please make sure to upload a image file.')
        return
      }
      if (FileSize > 10000000) {
        alert(
          'Unsuccessful Upload. Please make sure to upload file size less than 10 MB.'
        )
        return
      }

      if (file.type.match('image.*')) {
        console.log(file);
        this.footer_img = file;
        this.footer_img_pre = await getBase64(file)

        let footer_img_url = await uploadFileCampaign("footer", this.campaign.campaign_name, this.footer_img)
        await axios.put(`${baseurl()}/api/admin/updateCampaign`, {
          campaign_id: this.campaign.campaign_id,
          footer_img: footer_img_url
        })
        await this.getAllBrand()
      }
      e.target.value = null
    },

    async onFileBrandThumbnailSelected(e) {
      const target = e.target
      console.log(target.files.length);
      const file = (target.files)[0]

      console.log('file:', file.type.match('image.*'))
      const FileSize = file.size

      console.log('size:', FileSize)
      if (!file.type.match('image.*')) {
        alert('Unsuccessful Upload. Please make sure to upload a image file.')
        return
      }
      if (FileSize > 10000000) {
        alert(
          'Unsuccessful Upload. Please make sure to upload file size less than 10 MB.'
        )
        return
      }

      if (file.type.match('image.*')) {
        console.log(file);
        this.brand_thumbnail_img = file;
        this.brand_thumbnail_img_pre = await getBase64(file)
      }
      e.target.value = null
    },

    async onFileBrandHeaderSelected(e) {
      const target = e.target
      console.log(target.files.length);
      const file = (target.files)[0]

      console.log('file:', file.type.match('image.*'))
      const FileSize = file.size

      console.log('size:', FileSize)
      if (!file.type.match('image.*')) {
        alert('Unsuccessful Upload. Please make sure to upload a image file.')
        return
      }
      if (FileSize > 10000000) {
        alert(
          'Unsuccessful Upload. Please make sure to upload file size less than 10 MB.'
        )
        return
      }

      if (file.type.match('image.*')) {
        console.log(file);
        this.brand_header_img = file;
        this.brand_header_img_pre = await getBase64(file)
      }
      e.target.value = null
    },

    async onFileBrandFooterSelected(e) {
      const target = e.target
      console.log(target.files.length);
      const file = (target.files)[0]

      console.log('file:', file.type.match('image.*'))
      const FileSize = file.size

      console.log('size:', FileSize)
      if (!file.type.match('image.*')) {
        alert('Unsuccessful Upload. Please make sure to upload a image file.')
        return
      }
      if (FileSize > 10000000) {
        alert(
          'Unsuccessful Upload. Please make sure to upload file size less than 10 MB.'
        )
        return
      }

      if (file.type.match('image.*')) {
        console.log(file);
        this.brand_footer_img = file;
        this.brand_footer_img_pre = await getBase64(file)
      }
      e.target.value = null
    },


    async deleteFooterImg() {
      this.footer_img = null
      this.footer_img_pre = null
      this.foorer_img_url = null
      await axios.put(`${baseurl()}/api/admin/updateCampaign`, {
        campaign_id: this.campaign.campaign_id,
        footer_img: null
      })
      await this.getAllBrand()
    },
    async deleteHeaderImg() {
      this.header_img = null
      this.header_img_pre = null
      this.header_img_url = null
      await axios.put(`${baseurl()}/api/admin/updateCampaign`, {
        campaign_id: this.campaign.campaign_id,
        header_img: null
      })
      await this.getAllBrand()
    },
    deleteBrandHeaderImg() {
      this.brand_header_img = null
      this.brand_header_img_pre = null
      this.brand_header_img_url = null
    },
    deleteBrandFooterImg() {
      this.brand_footer_img = null
      this.brand_footer_img_pre = null
      this.brand_footer_img_url = null
    },
    deleteBrandThumbnailImg() {
      console.log("delete");
      this.brand_thumbnail_img = null
      this.brand_thumbnail_img_pre = null
      this.brand_thumbnail_img_url = null
    },


    async publishCampaign() {
      try {
        this.$vs.loading({color: "#DDDDDD"})
        let rows = await this.$refs.BrandTable.getSelectedRowData()
        console.log("rows:", rows);
        let brand_id_publish = rows.map((each) => {
          return { brand_id: each.brand_detail.brand_id, is_publish: true }
        })

        await axios.put(`${baseurl()}/api/admin/updatePublishBrandMultiple`, {
          brand_id_publish
        })
        await this.getAllBrand()
        this.$vs.loading.close()
      } catch (error) {
        console.log(error);
        this.$vs.loading.close()
      }

    },
    async unPublishCampaign() {
      try {
        this.$vs.loading({color: "#DDDDDD"})
        let rows = await this.$refs.BrandTable.getSelectedRowData()
        console.log("rows:", rows);
        let brand_id_publish = rows.map((each) => {
          return { brand_id: each.brand_detail.brand_id, is_publish: false }
        })

        await axios.put(`${baseurl()}/api/admin/updatePublishBrandMultiple`, {
          brand_id_publish
        })
        await this.getAllBrand()
        this.$vs.loading.close()
      } catch (error) {
        console.log(error);
        this.$vs.loading.close()
      }
    },

    async updateCampaignName() {
      try {
        this.$vs.loading({color: "#DDDDDD"});
        if (this.campaign.campaign_name && this.campaign.campaign_name.trimLeft() !== "") {
          await axios.put(`${baseurl()}/api/admin/updateCampaign`, {
            "campaign_id": this.campaign.campaign_id,
            "campaign_name": this.campaign.campaign_name,
          })
        }
        await this.getAllBrand()
        this.$vs.loading.close();
      } catch (error) {
        if(error.response.data.message){
          alert(error.response.data.message)
        }else{
          alert(error)
        }
        
        this.$vs.loading.close();
      }
    },
    async updateCampaignKeyword() {
      try {
        this.$vs.loading({color: "#DDDDDD"});
        if (this.campaign.campaign_name && this.campaign.campaign_name.trimLeft() !== "") {
          await axios.put(`${baseurl()}/api/admin/updateCampaign`, {
            "campaign_id": this.campaign.campaign_id,
            "line_keyword": this.campaign.line_keyword,
          })
        }
        await this.getAllBrand()
        this.$vs.loading.close();
      } catch (error) {
        if(error.response.data.message){
          alert(error.response.data.message)
        }else{
          alert(error)
        }
        
        this.$vs.loading.close();
      }
    },

    async updateCampaignDate() {
      try {
        this.$vs.loading({color: "#DDDDDD"});
        await axios.put(`${baseurl()}/api/admin/updateCampaign`, {
          "campaign_id": this.campaign.campaign_id,
          "start_date": this.campaign.start_date,
          "end_date": this.campaign.end_date
        })

        await this.getAllBrand()
        this.$vs.loading.close();
      } catch (error) {
        if(error.response.data.message){
          alert(error.response.data.message)
        }else{
          console.log(error);
        }
        this.$vs.loading.close();
      }
    },
    async updateCampaignIsPublish(){
      try {
        await axios.put(`${baseurl()}/api/admin/updatePublishCampaign`, {
          "campaign_id": this.campaign.campaign_id,
          "is_publish": this.campaign.is_publish
        })
      } catch (error) {
        if(error.response.data.message){
          alert(error.response.data.message)
        }else{
          alert(error)
        }
      }
    },
    async updateCampaignHtml() {
      try {
        let res = await axios.put(`${baseurl()}/api/website/updateHtml`, {
          campaign_id: this.campaign.campaign_id
        })
        if (res.status === 200) {
          await this.getAllBrand()
          alert("success")
        }
      } catch (error) {
        alert("please try again")
      }
    },
    async updateLandingCampaignHtml() {
      try {
        let res = await axios.put(`${baseurl()}/api/website/updateHtml/landing`, {
          campaign_id: this.campaign.campaign_id
        })
        if (res.status === 200) {
          await this.getAllBrand()
          alert("success")
        }
      } catch (error) {
        alert("please try again")
      }
    },
    addTimeStamp(url) {
      // console.log(`${url}?t=${(new Date()).valueOf()}`);
      return `${url}?t=${(new Date()).valueOf()}`
    },
    async previewLandingPage() {
      try {
        let res = await axios.get(`${baseurl()}/api/website/preview/landing/${this.campaign.campaign_id}`)
        console.log(res);
        this.previewLanding = res.data
        this.showPreviewLanding = true
      } catch (error) {
        if(error.response.data.message){
          alert(error.response.data.message)
        }else{
          alert(error)
        }
      }
    },
    closePreviewLanding() {
      this.showPreviewLanding = false
      this.previewLanding = null
    },
    async BrandUp(val) {
      console.log("Up:", val);
      try {
        await axios.put(`${baseurl()}/api/admin/orderbrand/up`, {
          campaign_id: val.campaign_id,
          brand_id: val.brand_id
        })
        await this.getAllBrand()


      } catch (error) {
        if(error.response.data.message){
          alert(error.response.data.message)
        }else{
          alert(error)
        }
      
      }
    },
    async BrandDown(val) {
      console.log("Down:", val);
      try {
        await axios.put(`${baseurl()}/api/admin/orderbrand/down`, {
          campaign_id: val.campaign_id,
          brand_id: val.brand_id
        })

        await this.getAllBrand()


      } catch (error) {
        if(error.response.data.message){
          alert(error.response.data.message)
        }else{
          alert(error)
        }
     
      }
    },
    isAllowClick(val){
      this.allowClick = val
    },

    OpenImageModal(url){
      this.modal_img_url = url
      this.showPreviewImage = true
    },
    closePreviewImage(){
      this.modal_img_url = null
      this.showPreviewImage = false
    }
   


  },


  mounted() {
    // this.$vs.loading({color: "#DDDDDD"});
    this.$vs.loading.close();

  },

  components: {
    AgGrid,
    BrandTable,
    BlankModal,
    DatePicker,
    MiniBlankModal
  },
};

const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = (error) => reject(error)
  })
}

async function compressImage(file) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 1,
      maxHeight: 12000,
      success(result) {
        resolve(result);
      },
      error(e) {
        reject(e);
      },
    });
  });
}

const uploadFileBrand = async (file, brandname, campaignname, type) => {
  const fileforupload = await compressImage(file);
  let fd = new FormData();
  fd.append("brand_name", brandname);
  if (type) { fd.append("img_type", type); }
  fd.append("campaign_name", campaignname)
  fd.append("file", fileforupload);
  try {
    let res = await axios.post(`${baseurl()}/api/website/uploadImg`,
      fd
    );
    return res.data.url;
  } catch (error) {
    throw (error)
  }
}

const uploadFileCampaign = async (type, campaignname, file) => {
  const fileforupload = await compressImage(file);
  let fd = new FormData();
  if (type) { fd.append('img_type', type) }

  fd.append("campaign_name", campaignname)
  fd.append("file", fileforupload);
  try {
    let res = await axios.post(`${baseurl()}/api/website/uploadImg`,
      fd
    );
    return res.data.url
  } catch (error) {
    throw (error)
  }
}
</script>

<style lang="scss" scoped>
.img-box {
  border: 1px solid #6A7E91;
  border-radius: 10px;
  width: 14rem;
  height: 18rem;
  background-color: #FFFFFF;
}

.img-element {
  width: 13.8rem;
  height: 17.8rem;
}


.img-modal{
  width: 50vw;
}


.cus-select ::v-deep .custom-select{
  border: 1px solid #6A7E91;
}



</style>
