<template>
  <div class="h-full w-full" id="ag-grid-demo">
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="gridOptions"
          class="ag-theme-material ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="rowData"
          @selection-changed="onSelectionChanged"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :enableRtl="$vs.rtl"
          @first-data-rendered="onFirstDataRendered"
          :setQuickFilter="updateSearchQuery"
          @gridReady="onGridReady"
          :overlayLoadingTemplate="overlayLoadingTemplate"
        >
          <!-- 
          :pagination="true"
          :suppressPaginationPanel="true"
          :paginationPageSize="paginationPageSize"
          @rowDataChanged="onFirstDataRendered" -->
        </ag-grid-vue>
    </div>
  
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import topButton from "../topTableButton/topTableButton";
import { mapGetters } from "vuex";
import iconDetail from "./table_component/iconDetail";
import iconDelete from "./table_component/iconDelete";
import tabComponent from "./../tabComponent/tabComponent";
import Dropdown from "./table_component/Dropdown";
import String from "./table_component/String";
import Date from "./table_component/Date";
import Float from "./table_component/Float";
import Number from "./table_component/Number";
import Array from "./table_component/Array";
import Publish from "./table_component/Publish";
import Edit from "./table_component/Edit";
import EditProduct from "./table_component/EditProduct";
// import CreateProduct from "./../../elements/";
import Delete from "./table_component/Delete";
import Detail from "./table_component/Detail.vue";
import Datepicker from "vuejs-datepicker";
import TogglePublish from "./table_component/TogglePublish.vue";
import moment from "moment";
import iconUp from "./table_component/iconUp.vue";
import iconDown from "./table_component/iconDown.vue";
import FormatTime from "./table_component/FormatTime.vue";

import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import themeConfig from "@/../themeConfig.js";


export default {
  emits: [ "brand-up" , 'brand-down'],
  beforeMount() {
    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';

      this.columnDefs = [
        // {
        // headerName: "",
        // field: "checkbox",
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: true,
        // minWidth: 200,
        // },
      {
        headerName: "No",
        field: "index",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        minWidth: 200,
        valueGetter: "node.rowIndex + 1",
        getQuickFilterText: (params) => {
          return params.value;
        },
      },
     
      {
        headerName: 'Brand name',
        field: "brand_name",
        minWidth: 200,
        getQuickFilterText: (params) => {
          return params.value;
        },
      },
     
        {
        headerName: 'Propagation',
        field: "is_publish",
        cellRendererFramework: "TogglePublish",
        minWidth: 200,
      },
      {
        headerName: "",
        field: "up",
        minWidth: 50,
        cellRendererFramework: "iconUp",
        cellRendererParams: {
         orderUpValue: this.orderUpValue.bind(this),
        }
      },
      {
        headerName: "",
        field: "down",
        minWidth: 50,
        cellRendererFramework: "iconDown",
        cellRendererParams: {
         orderDownValue: this.orderDownValue.bind(this),
        }
      },
        {
        headerName: 'Detail',
        field: "brand_detail",
        cellRendererFramework: "Detail",
        minWidth: 200,
      },
     
    ];
  },
  components: {
    AgGridVue,
    Datepicker,
    iconDetail,
    iconDelete,
    tabComponent,
    topButton,
    Dropdown,
    String,
    Date,
    Float,
    Number,
    Publish,
    Array,
    Edit,
    Delete,
    Detail,
    TogglePublish,
    iconUp,
    iconDown,
    FormatTime
  },
   props: {
    searchText: {
      type: String,
      default: ""
    },
    rowData:{
        type: Array
    }
  },
  data() {
    return {
      bermuda: null,
      searchQuery: "",
      gridOptions: { resizable: true },
      maxPageNumbers: 7,
      gridApi: "sizeColumnsToFit",
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      start_date: null,
      end_date: null,
      masks: {
        input: "YYYY-MM-DD"
      },
      username: null,
      password: null,
      name: null,
      role_choice: ["Admin", "Super Admin"],
      role: null,
      columnFilter: null,
      selectFilter: null,
      overlayLoadingTemplate: null,
      columnDefs: null
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    "searchText"(val){
      this.gridApi.setQuickFilter(val);
    }
  },

  computed: {
    ...mapGetters({
      //getTable: "general/table",
      //nowPage: "getNowPage",
      activePage: "general/activePage",
      page: "general/page",
      pointer: "general/pointer"
    }),
    Showaddbutton() {
      if (
        this.page.table.add_button.value === true &&
        this.pointer !== "banner"
      ) {
        console.log("check");
        return true;
      }
    },

    start_date_moment() {
      // console.log("start_date", this.start_date);
      const start_date = moment().format("YYYY-MM-01");
      return start_date;
    },
    end_date_moment() {
      // console.log("his.end_date", this.end_date);
      const end_date = moment().format("YYYY-MM-") + moment().daysInMonth();

      return end_date;
    },

    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 25;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    },
    UpdateData() {
      console.log(">>>>", this.page.table.data);
      return this.page.table.data;
    },
    HeightDynamic() {
      return this.Height;
    }
  },
  methods: {
      getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes()
      let selectedData = selectedNodes.map((node) =>{
         return node.data
      }
      );
      return selectedData;
    },
    onSelectionChanged(){
      this.$emit("AllowClick",this.gridApi.getSelectedNodes().length !== 0 ? true : false)
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    changeTabActive(index) {
      this.$store.dispatch("home/changeTabActive", index);
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
    },
    openModal() {
      this.$bvModal.show("filter_button");
    },
    orderDownValue(value){
      this.$emit("brand-down",value)
    },
    orderUpValue(value){
      this.$emit("brand-up",value)
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.bermuda = themeConfig.theme.bermuda;
  },
  updated() {
    this.onSelectionChanged();
  },
};
</script>
<style>
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  outline: 0;
}

.search-table .vs-con-input .vs-inputx {
  line-height: 1.5;
  border: none !important;
}

.search-table .vs-con-input .material-icons {
  line-height: 1.5;
}

.add-button {
  border-radius: 10px;
  background-color: #0430ad !important;
  font-family: 'Montserrat', 'Athiti', sans-serif;;
}

.add-banner {
  border-radius: 10px;
  color: #0430ad;
  background-color: #ffffff !important;
  font-family: 'Montserrat', 'Athiti', sans-serif;;
  width: max-content;
}

.tablestyle {
  background: white;
}

.table th {
  color: #ffffff;
  vertical-align: middle;
  border-bottom: 1px solid black !important;
}
.table-bordered th {
  border-top: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  border-bottom: 1px solid black !important;
}
.table-bordered td {
  border-top: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  border-bottom: 1px solid #fff !important;
}
.table-bordered {
  border: 0 !important;
}

.fixjustify {
  display: flex !important;
  justify-content: end !important;
}

.tablename {
  font-size: 1.125rem;
}
</style>
